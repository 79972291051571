<template>
  <svg
    :width="width"
    :height="height" viewBox="0 0 32 25" fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.22222 0.5C2.30707 0.5 0.75 2.02148 0.75 3.89286V20.8571C0.75 22.7285 2.30707 24.25 4.22222 24.25H28.5278C30.4429 24.25 32 22.7285 32 20.8571V3.89286C32 2.02148 30.4429 0.5 28.5278 0.5H4.22222ZM8.5625 14.0714H12.0347C14.4327 14.0714 16.375 15.9693 16.375 18.3125C16.375 18.779 15.9844 19.1607 15.5069 19.1607H5.09028C4.61285 19.1607 4.22222 18.779 4.22222 18.3125C4.22222 15.9693 6.1645 14.0714 8.5625 14.0714ZM6.82639 8.98214C6.82639 8.0823 7.19221 7.21931 7.84338 6.58303C8.49455 5.94675 9.37772 5.58929 10.2986 5.58929C11.2195 5.58929 12.1027 5.94675 12.7538 6.58303C13.405 7.21931 13.7708 8.0823 13.7708 8.98214C13.7708 9.88198 13.405 10.745 12.7538 11.3813C12.1027 12.0175 11.2195 12.375 10.2986 12.375C9.37772 12.375 8.49455 12.0175 7.84338 11.3813C7.19221 10.745 6.82639 9.88198 6.82639 8.98214ZM20.7153 7.28571H27.6597C28.1372 7.28571 28.5278 7.66741 28.5278 8.13393C28.5278 8.60045 28.1372 8.98214 27.6597 8.98214H20.7153C20.2378 8.98214 19.8472 8.60045 19.8472 8.13393C19.8472 7.66741 20.2378 7.28571 20.7153 7.28571ZM20.7153 10.6786H27.6597C28.1372 10.6786 28.5278 11.0603 28.5278 11.5268C28.5278 11.9933 28.1372 12.375 27.6597 12.375H20.7153C20.2378 12.375 19.8472 11.9933 19.8472 11.5268C19.8472 11.0603 20.2378 10.6786 20.7153 10.6786ZM20.7153 14.0714H27.6597C28.1372 14.0714 28.5278 14.4531 28.5278 14.9196C28.5278 15.3862 28.1372 15.7679 27.6597 15.7679H20.7153C20.2378 15.7679 19.8472 15.3862 19.8472 14.9196C19.8472 14.4531 20.2378 14.0714 20.7153 14.0714Z" :fill="iconColor"/>
  </svg>


</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'iconBillingBotsSubscribers'
    },
    width: {
      type: [Number, String],
      default: 32
    },
    height: {
      type: [Number, String],
      default: 25
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>