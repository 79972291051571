const getTranslateFunc = function () {
  let i18n;

  try {
    i18n = require('@/lang/i18n.js').default;
  } catch (error) {
    // console.log(error);
  }

  return i18n
    ? (translateAlias) => i18n.t(translateAlias)
    : (translateAlias) => translateAlias;
};

const getTranslate = getTranslateFunc();

//Должен называться так же как и в modules_components_obj
let module_name = 'Billing';

let module_settings = {
  module_name,
};
//Корневой урл модуля
let module_alias = 'billing';

//Полный список разрешений модуля
module_settings.permits = {
  front: {
    //Отображение пунктов меню
    registered_users_show_menu_item: {
      description: 'Show in the menu',
    },
    //Отображение табов
    registered_users_show_messengers_tab_item: {
      description:
        'Show tab',
    },
  },

  back: {},
};

//Пункты в меню
module_settings.menu_items = [
  // {
  //   title: getTranslate('modules.bids.module_settings.bid'),
  //   module: module_name,
  //   alias: module_alias,
  //   icon: 'mdi-gondola',
  //   show: true,
  //   route: 'billing',
  // },
];

//Табы модуля
module_settings.tabs_items = {};

module_settings.tabs_items[module_name] = {
  use_tabs: true,
  tabs_items: [

  ],
};

//Подключенные компоненты
module_settings.components = [
  {
    name: module_name,
    path: 'Component/Billing.vue',
  },
];

//Экспорт для vue и для node
module.exports = { module_settings };
module.exports.default = { module_settings };
