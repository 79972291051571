<template>
  <v-menu
    bottom
    left
    offset-y
    nudge-bottom="6"
    :close-on-content-click="false"
    min-width="400"
    max-width="400"
    :dark="checkDarkThemeForRoute"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        color="primary"
        depressed
        icon
        class="mr-1"
      >
        <v-badge
          color="error"
          :content="notReadNotifications"
          :value="notReadNotifications"
          overlap
        >
          <v-icon
            size="21"
            v-text="opGetNotificationsEnabled ? 'mdi-bell-ring' : 'mdi-bell'"
          />
        </v-badge>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-layout :align-center="true" :justify-space-between="true">

          <MenuNotificationsSettings />

          {{$t('notification.title')}}

          <v-spacer />

          <v-btn
            v-tooltip="$t('notification.mark_read')"
            v-if="notReadNotifications"
            small
            color="primary"
            :ripple="false"
            icon
            @click="readAllNotifications()"
          ><v-icon v-text="'mdi-playlist-check'"></v-icon></v-btn>
        </v-layout>

      </v-card-title>
      <v-card-text class="px-0">
        <v-tabs
          height="34"
          :dark="checkDarkThemeForRoute"
        >
          <v-tab class="text-none">
            {{$t('notification.tab_info')}}
            <v-badge
              overlap
              color="primaryLighten"
              :value="infoNotificationsNew"
              :content="infoNotificationsNew"
              offset-x="3"
            >
              <span class="ml-1 font-weight-bold">({{infoNotifications.length || 0}})</span>
            </v-badge>

          </v-tab>
          <v-tab class="text-none">
            {{$t('notification.tab_warning')}}
            <v-badge
              overlap
              color="error"
              :value="errorNotificationsNew"
              :content="errorNotificationsNew"
              offset-x="3"
            >
              <span class="ml-1 font-weight-bold">({{errorNotifications.length || 0}})</span>
            </v-badge>
          </v-tab>

          <v-tab-item>
            <v-sheet
              height="300"
              :dark="checkDarkThemeForRoute"
            >
              <vue-scroll>
                <v-list dense max-width="400">
                  <v-list-item
                    v-for="(item, index) in infoNotifications"
                    :key="index"
                  >
                    <v-list-item-avatar size="20">
                      <v-icon
                        :color="item.message_type === 'url' ? 'success' : 'primary'"
                        v-text="'mdi-information'"
                        v-tooltip="item.text"
                      />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title v-text="item.title" />

                      <template
                        v-if="item.message_type === 'url'"
                      >
                        <a :href="$sanitizedHrefHttps( item.text )" target="_blank" rel="noopener" download>
                          {{item.text}}
                        </a>
                      </template>

                      <template
                        v-else-if="
                          item.message_type === 'link' &&
                          !!item?.message_data?.chat_room_id
                        "
                      >

                        <router-link
                          target="_blank" rel="noopener"
                          :to="{ name: 'OperatorPanelChats', query: { chat_room_id: item.message_data.chat_room_id } }"
                        >
                          {{ item.text }}
                        </router-link>

                      </template>

                      <template
                        v-else-if="
                          item.message_type === 'web_hook'
                        "
                      >
                        <div>
                          {{ item.text }}
                          <span class="font-weight-bold"> {{item?.message_data?.messenger_name}}</span>
                        </div>

                        <router-link
                          target="_blank" rel="noopener"
                          :to="{ name: 'bot_settings', params: { id: item?.message_data?.bot_id } }"
                        >
                          {{$t('modules.dialog.texts.settings.settings_of')}}
                        </router-link>

                      </template>

                      <template v-else>
                        <div
                          class="text-caption"
                          v-text="item.text"
                        />
                      </template>

                      <div class="text-caption gray--text">{{getNotificationDate(item.date)}}</div>
                    </v-list-item-content>

                    <v-chip class="ml-2" small v-if="item.read === false">New</v-chip>
                  </v-list-item>
                </v-list>
              </vue-scroll>
            </v-sheet>
          </v-tab-item>

          <v-tab-item>
            <v-sheet height="300" :dark="checkDarkThemeForRoute">
              <vue-scroll>
                <v-list dense max-width="400">
                  <v-list-item
                    v-for="(item, index) in errorNotifications"
                    :key="index"
                  >
                    <v-list-item-avatar size="20">
                      <v-icon color="error" v-tooltip="item.text" v-text="'mdi-alert-circle'" />
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title v-text="item.title" />
                      <v-list-item-subtitle v-text="item.text"></v-list-item-subtitle>
                      <div class="text-caption gray--text">{{getNotificationDate(item.date)}}</div>
                    </v-list-item-content>

                    <v-chip class="ml-2" small v-if="item.read === false">New</v-chip>
                  </v-list-item>
                </v-list>
              </vue-scroll>
            </v-sheet>

          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>

import {mapGetters} from "vuex";
import HELPERS from "@/modules/OperatorPanel/helpers";
import MenuNotificationsSettings from "@/components/Modules/Menus/MenuNotificationsSettings.vue";

export default {
  name: 'MenuNotifications',

  components: {
    MenuNotificationsSettings,
  },

  data: () => ({

  }),

  mounted() {
    this.getNotificationsFromStorage()
  },

  computed: {
    ...mapGetters([
      'opGetDarkThemeCookie',
      'opGetNotificationsEnabled',
      'getNotifications',
    ]),

    infoNotifications() {
      return this.getNotifications.filter(item => item.type === 'info')?.reverse()
    },

    infoNotificationsNew() {
      return this.getNotifications.filter(item => item.type === 'info' && !item?.read)?.length || ''
    },

    errorNotifications() {
      return this.getNotifications.filter(item => item.type === 'error')?.reverse()
    },

    errorNotificationsNew() {
      return this.getNotifications.filter(item => item.type === 'error' && !item?.read)?.length || ''
    },

    notReadNotifications() {
      const notRead = this.getNotifications.filter(item => item.read === false)
      return notRead.length
    },

    checkDarkThemeForRoute() {
      return this.opGetDarkThemeCookie && this.$route?.name === 'OperatorPanelChats'
    },
  },

  methods: {
    getNotificationDate (date) {
      return HELPERS.formatLastMessageTimeForDialogItem(date) || ''
    },

    getNotificationsFromStorage() {
      let notifications = []

      if(localStorage.getItem('kw_notifications')) {
        try {
          notifications = JSON.parse(localStorage.getItem('kw_notifications'))
        } catch(e) {
          this.$consoleLogHandler({ text: 'error parse notifications', data: e })
        }
      }

      if(notifications?.length) {

        //slice notification to limit
        if(notifications.length > 99) {
          notifications = notifications.slice(Math.max(notifications.length - 99, 1))
        }
        //end slice notification to limit

        this.$store.dispatch('setNotifications', notifications)
      }
    },

    readAllNotifications() {
      for(let i in this.getNotifications) {
        this.getNotifications[i].read = true
      }

      //save notifications to storage
      localStorage.setItem(
        'kw_notifications',
        JSON.stringify(this.getNotifications),
      )
    },
  },
}
</script>