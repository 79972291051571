import LAYOUT from '@/layout/layout';
import billingLayout from '@/modules/Billing/layout/Layout.vue';
import billingSettings from '@/modules/Billing/views/BillingSettings.vue';
import settingsLayout from '@/views/Settings/SettingsPage.vue';

export default [
  {
    path: '/billing',
    component: LAYOUT.base,
    children: [
      {
				path: '/',
				component: billingLayout,
        children: [
          {
            path: '',
            component: settingsLayout,

						children: [
							{
								path: '',
								name: 'BillingSettings',
								component: billingSettings,

								children: [
									{
										path: 'tariffs-and-payments',
										name: 'tariffs_payments',
										component: () => import(/* webpackChunkName: 'bids-section' */ '@/modules/Billing/views/pages/TariffsAndPayments.vue'),
									},
								],
							},
						],
          },
        ],
      },
    ],
  },
];
