/*
 Управление модулями
 */

//Список доступных модулей, а также их активность
const extra_modules_list = [
  {
    component_name: 'OperatorPanel',
    active: true,
  }, 
  {
    component_name: 'Statistics',
    active: true,
  },
  {
    component_name: 'BroadcastUsers',
    active: true,
  },
  {
    component_name: 'Broadcast',
    active: true,
  },
  {
    component_name: 'FileManager',
    active: true,
  },
  {
    component_name: 'RegisteredUsers',
    active: false,
  },
  {
    component_name: 'Reviews',
    active: false,
  },
  {
    component_name: 'Polls',
    active: false,
  },
  {
    component_name: 'Bids',
    active: false,
  },
  {
    component_name: 'CustomData',
    active: true,
  },
  {
    component_name: 'ActionJail',
    active: true,
  },
  {
    component_name: 'FastLine',
    active: true,
  },
	{
		component_name: 'Billing',
		active: true,
	},
];

module.exports = {
  extra_modules_list,
};
