<template>
  <div
    class="pa-3 d-flex align-end"
  >
    <div>
      <div class="text-h6 font-weight-bold">
        <slot name="title_path" />
        {{$t(`${title_path}`)}}
      </div>

      <div class="text-caption">
        <slot name="description_path" />
        <template v-if="$te(`${title_path}`)">
          {{$t(`${description_path}`)}}
        </template>
      </div>

      <div
        v-if="$te(`${links_path}`)"
      >
        <template
          v-for="(item, key) in $t(`${links_path}`)"
        >

          <a
            :key="key"
            :href="item.link"
            target="_blank"
            class="text-caption mr-3 text-decoration-none"
          >
            <v-avatar
              color="primary"
              size="20"
              class="mr-1"
            >
              <v-icon
                color="white"
                size="12"
                v-text="'mdi-help'"
              />
            </v-avatar>
            <span class="text-decoration-underline">{{item.text}}</span>
          </a>
        </template>
      </div>
    </div>

    <v-spacer />

    <slot name="right-btns" />
  </div>
</template>

<script>

export default {
  name: 'SettingHeader',

  props: {
    title_path: {
      type: String,
      default: ''
    },
    description_path: {
      type: String,
      default: ''
    },
    links_path: {
      type: String
    },
  }
}
</script>