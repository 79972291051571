<template>
  <v-layout class="settings-page__wrapper">
    <v-sheet color="gray2" class="settings-page__menu">

        <v-sheet height="300" class="flex-grow-1" color="transparent">
          <vue-scroll class="settings-page__menu--scroll">
            <v-list
            flat
            dense
            color="transparent"
            expand
          >
            <v-list-group
              no-action
              :value="true"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-uppercase font-weight-bold"
                    v-text="$t('modules.op.search.bots_title')"
                  />
                </v-list-item-content>
              </template>

              <v-list-item
                link
                to="/settings-page/bots"
                :disabled="checkPermission('bots')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.settings.bots.title')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/settings-page/bot-groups"
                :disabled="checkPermission('botsGroups')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.dialog.module_settings.bot_groups')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/settings-page/user-fields"
                :disabled="checkPermission('users')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.user_fields.header.title')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/settings-page/client-fields"
                :disabled="checkPermission('users')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.client_fields.header.title')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-group
              no-action
              :value="true"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-uppercase font-weight-bold"
                    v-text="$t('modules.op.module_settings.users')"
                  />
                </v-list-item-content>
              </template>

              <v-list-item
                link
                to="/settings-page/users"
                :disabled="checkPermission('users')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.module_settings.users')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/settings-page/roles"
                :disabled="checkPermission('roles')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.dialog.texts.settings.roles.roles')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-group
              no-action
              :value="true"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-uppercase font-weight-bold"
                    v-text="$t('modules.op.module_settings.operator_panel')"
                  />
                </v-list-item-content>
              </template>

              <v-list-item
                link
                to="/settings-page/op-users"
                :disabled="checkPermission('users')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.module_settings.operators')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/settings-page/subjects"
                :disabled="checkPermission('subjects')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.module_settings.subjects')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/settings-page/skill_groups"
                :disabled="checkPermission('skillGroups')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.module_settings.skill_groups')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/settings-page/statuses"
                :disabled="checkPermission('statuses')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.module_settings.statuses')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/settings-page/chat_statuses"
                :disabled="checkPermission('chatStatuses')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.module_settings.chatStatuses')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/settings-page/tags"
                :disabled="checkPermission('tags')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.module_settings.tags')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/settings-page/priorities"
                :disabled="checkPermission('priorities')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.module_settings.priorities')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                link
                to="/settings-page/metrics"
                :disabled="checkPermission('metrics')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.metrics.entity.plural')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                link
                to="/settings-page/calendars"
                :disabled="checkPermission('calendars')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.calendars.entity.plural')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            </v-list-group>

            <v-list-group
              no-action
              :value="true"

            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-uppercase font-weight-bold"
                    v-text="$t('modules.op.module_settings.settings')"
                  />
                </v-list-item-content>
              </template>

              <v-list-item
                link
                to="/settings-page/op-settings/timers"
                :disabled="checkPermission('settings')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.settings.entities.SettingsTimers')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/settings-page/op-settings/operators"
                :disabled="checkPermission('settings')"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="$t('modules.op.settings.entities.SettingsOperators')"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/settings-page/op-settings/styles-scripts"
                :disabled="checkPermission('settings')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.texts.style_editor')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/settings-page/op-settings/files"
                :disabled="checkPermission('settings')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.texts.file_settings')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/settings-page/op-settings/client-fields"
                :disabled="checkPermission('settings')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.texts.client_card_fields')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/settings-page/op-settings/chats"
                :disabled="checkPermission('settings')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.texts.dialog_setting')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/settings-page/op-settings/tickets"
                :disabled="checkPermission('settings')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.texts.ticket_setting')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/settings-page/op-settings/hot-keys"
                :disabled="checkPermission('settings')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.texts.hot_keys_setting')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-group
              no-action
              :value="true"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-uppercase font-weight-bold"
                    v-text="$t('modules.op.module_settings.quick_replies')"
                  />
                </v-list-item-content>
              </template>

              <v-list-item
                link
                to="/settings-page/quick_reply_categories"
                :disabled="checkPermission('fastAnswersCategories')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.module_settings.quick_reply_categories')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                link
                to="/settings-page/quick_replies"
                :disabled="checkPermission('fastAnswers')"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="$t('modules.op.module_settings.quick_replies')"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

              <v-list-group
                no-action
                :value="true"
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-uppercase font-weight-bold"
                      v-text="$t('modules.billing.header.title')"
                    />
                  </v-list-item-content>
                </template>

                <v-list-item
                  link
                  to="/billing/tariffs-and-payments"
                  :disabled="checkPermission('billing')"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="$t('modules.billing.settings.entities.tariffs_payments.header.title')"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
          </v-list>
          </vue-scroll>
        </v-sheet>

        <v-sheet
          v-if="checkIsAdmin()"
          height="auto"
          class="text-center py-4"
          color="transparent"
        >
          <v-btn
            color="primary"
            small
            link
            to="/settings-page/instance-settings"
            elevation="0"
          >
            {{$t('modules.dialog.module_settings.instance_settings')}}
          </v-btn>
        </v-sheet>
    </v-sheet>

    <v-sheet class="settings-page__body">
      <div class="settings-page__body--content">
        <div
          v-if="checkRouteIsMain()"
          class="d-flex align-center justify-center gray--text fill-height pa-5"
        >
          {{$t('modules.dialog.texts.settings_page_welcome_text')}}
        </div>
        <router-view></router-view>
      </div>
    </v-sheet>
  </v-layout>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'SettingsAll',

  data: () => ({

  }),

  computed: {
    ...mapGetters([
      'user'
    ]),
  },

  methods: {
    checkRouteIsMain() {
      return this.$route?.name === 'SettingsTab'
    },

    checkIsAdmin() {
      return !!this.user?.root
    },

    checkPermission(name) {
      let canShow = true

      //Bots Settings
      if(['bots', 'botsGroups', 'users', 'roles', 'billing'].includes(name)) {
        canShow = this.user.role.permissions?.settings?.sections?.[name]?.canShow;
      }

      //Operator Panel
      if ([
        'users',
        'subjects',
        'statuses',
        'skillGroups',
        'settings',
        'fastAnswersCategories',
        'fastAnswers',
        'chatStatuses',
        'chatHistory',
        'tags',
        'priorities',
        'metrics',
        'calendars'
      ].includes(name)) {
        canShow = this.user.role.permissions?.operatorPanel?.settings?.sections?.[name]?.canShow;
      }

      return !canShow
    }
  },
}
</script>


<style scoped lang="scss">
.settings-page {
  $this: 'settings-page';

  &__wrapper {
    height: calc(100vh - 60px);
    max-height: calc(100vh - 60px);
    overflow: hidden;
  }

  &__menu {
    width: 280px;
    max-width: 280px;
    display: flex;
    flex-direction: column;

    .v-list-group--no-action > .v-list-group__items > .v-list-item {
      padding-left: 40px !important;
    }

    .v-list-item__title, .v-list-item__subtitle {
      white-space: unset;
    }

    &--scroll {
      &::v-deep {
        .__view {
          width: 100% !important;
        }
      }
      overflow-x: hidden;

    }
  }

  &__body {
    width: calc(100% - 280px);
    max-width: calc(100% - 280px);

    &--content {
      height: 100%;
      max-height: 100%;
      overflow: hidden;

      &::v-deep {
        & > .h-100-percent,
        .table-settings-op__wrapper {
          max-height: 100%;
          display: flex;
          flex-direction: column;

          .v-data-table{
            flex: 1 1 auto;
            overflow: hidden;
            min-height: 0;

            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    &::v-deep {
      .__view {
        width: 100% !important;
      }

      .op-settings-tab,
      .settings-intance {
        max-height: 100%;
        overflow-y: auto;
      }
    }
  }

}

</style>
