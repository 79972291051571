<template>
  <div class="h-100-percent">
    <!-- Header -->
    <SettingHeader
      :links_path="getRouteLinks()"
    >
      <template v-slot:title_path>
        {{
          $t(`modules.${module_name}.header.title`) +
          getRouteName()
        }}
      </template>
      <template v-slot:description_path>
        {{ getRouteDescription() }}
      </template>

      <template v-slot:right-btns></template>
    </SettingHeader>
    <!-- end Header -->

    <v-container
      class="op-settings-tab ml-0"
    >
      <router-view></router-view>
    </v-container>
  </div>
</template>

<script>

import SettingHeader from "@/views/Settings/tabs/components/SettingHeader.vue";
import i18n from "@/lang/i18n";

export default {
  name: 'BillingSettings',
  components: {SettingHeader},
  data() {
    return {
      module_name: 'billing',
      entity: 'setting',
      multiple_entity: 'settings',
    };
  },

  methods: {
    getRouteName(){
      return i18n.te(`modules.billing.settings.entities.${this.$route.name}.header.title`)
        ? ` - ${i18n.t(`modules.billing.settings.entities.${this.$route.name}.header.title`)}`
        : '';
    },

    getRouteDescription() {
      return i18n.te(`modules.billing.settings.entities.${this.$route.name}.header.description`)
        ? `${i18n.t(`modules.billing.settings.entities.${this.$route.name}.header.description`)}`
        : '';
    },

    getRouteLinks() {
      //modules.op.${multiple_entity}.header.links
      return `modules.billing.settings.entities.${this.$route.name}.header.links`
    },
  },
};
</script>
